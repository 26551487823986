import { OptionItem } from '@/common/types';
import { gql } from '@apollo/client';
import { useMemo } from 'react';
import { adjustAssetsForSelect } from '../utils';
import {
  type HierarchicalAsset_AssetFragment,
  useHierarchicalAssetSuspenseQuery,
} from './useHierarchicalAssets.generated';

gql`
  fragment HierarchicalAsset_Asset on HierarchicalAsset {
    id
    name
    childAssets {
      id
      name
      childAssets {
        id
        name
        childAssets {
          id
          name
          childAssets {
            id
            name
          }
        }
      }
    }
  }
`;

gql`
  query HierarchicalAsset {
    hierarchicalAssets {
      ...HierarchicalAsset_Asset
    }
  }
`;

type ReturnType = {
  hierarchicalAssets: HierarchicalAsset_AssetFragment[];
  selectAssetItems: OptionItem[];
  refetchHierarchicalAssets: () => void;
};

export const useHierarchicalAssets = (): ReturnType => {
  const {
    data: { hierarchicalAssets },
    refetch: refetchHierarchicalAssets,
  } = useHierarchicalAssetSuspenseQuery();

  // useEffectの監視対象にすると、useEffectが無限ループするため、useMemoでキャッシュする
  const selectAssetItems = useMemo(
    () => hierarchicalAssets.map(adjustAssetsForSelect),
    [hierarchicalAssets]
  );

  return {
    hierarchicalAssets,
    selectAssetItems,
    refetchHierarchicalAssets,
  };
};
