import * as Types from '../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HierarchicalAsset_AssetFragment = (
  { __typename?: 'HierarchicalAsset' }
  & Pick<Types.HierarchicalAsset, 'id' | 'name'>
  & { childAssets?: Types.Maybe<Array<(
    { __typename?: 'HierarchicalAsset' }
    & Pick<Types.HierarchicalAsset, 'id' | 'name'>
    & { childAssets?: Types.Maybe<Array<(
      { __typename?: 'HierarchicalAsset' }
      & Pick<Types.HierarchicalAsset, 'id' | 'name'>
      & { childAssets?: Types.Maybe<Array<(
        { __typename?: 'HierarchicalAsset' }
        & Pick<Types.HierarchicalAsset, 'id' | 'name'>
        & { childAssets?: Types.Maybe<Array<(
          { __typename?: 'HierarchicalAsset' }
          & Pick<Types.HierarchicalAsset, 'id' | 'name'>
        )>> }
      )>> }
    )>> }
  )>> }
);

export type HierarchicalAssetQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HierarchicalAssetQuery = (
  { __typename?: 'Query' }
  & { hierarchicalAssets: Array<(
    { __typename?: 'HierarchicalAsset' }
    & Pick<Types.HierarchicalAsset, 'id' | 'name'>
    & { childAssets?: Types.Maybe<Array<(
      { __typename?: 'HierarchicalAsset' }
      & Pick<Types.HierarchicalAsset, 'id' | 'name'>
      & { childAssets?: Types.Maybe<Array<(
        { __typename?: 'HierarchicalAsset' }
        & Pick<Types.HierarchicalAsset, 'id' | 'name'>
        & { childAssets?: Types.Maybe<Array<(
          { __typename?: 'HierarchicalAsset' }
          & Pick<Types.HierarchicalAsset, 'id' | 'name'>
          & { childAssets?: Types.Maybe<Array<(
            { __typename?: 'HierarchicalAsset' }
            & Pick<Types.HierarchicalAsset, 'id' | 'name'>
          )>> }
        )>> }
      )>> }
    )>> }
  )> }
);

export const HierarchicalAsset_AssetFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"HierarchicalAsset_Asset"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"HierarchicalAsset"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"childAssets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"childAssets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"childAssets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"childAssets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export const HierarchicalAssetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"HierarchicalAsset"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hierarchicalAssets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"HierarchicalAsset_Asset"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"HierarchicalAsset_Asset"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"HierarchicalAsset"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"childAssets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"childAssets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"childAssets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"childAssets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useHierarchicalAssetQuery__
 *
 * To run a query within a React component, call `useHierarchicalAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useHierarchicalAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHierarchicalAssetQuery({
 *   variables: {
 *   },
 * });
 */
export function useHierarchicalAssetQuery(baseOptions?: Apollo.QueryHookOptions<HierarchicalAssetQuery, HierarchicalAssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HierarchicalAssetQuery, HierarchicalAssetQueryVariables>(HierarchicalAssetDocument, options);
      }
export function useHierarchicalAssetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HierarchicalAssetQuery, HierarchicalAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HierarchicalAssetQuery, HierarchicalAssetQueryVariables>(HierarchicalAssetDocument, options);
        }
export function useHierarchicalAssetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HierarchicalAssetQuery, HierarchicalAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HierarchicalAssetQuery, HierarchicalAssetQueryVariables>(HierarchicalAssetDocument, options);
        }
export type HierarchicalAssetQueryHookResult = ReturnType<typeof useHierarchicalAssetQuery>;
export type HierarchicalAssetLazyQueryHookResult = ReturnType<typeof useHierarchicalAssetLazyQuery>;
export type HierarchicalAssetSuspenseQueryHookResult = ReturnType<typeof useHierarchicalAssetSuspenseQuery>;
export type HierarchicalAssetQueryResult = Apollo.QueryResult<HierarchicalAssetQuery, HierarchicalAssetQueryVariables>;